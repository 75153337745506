<!-- TablePage组件 -->
<template>
  <div style="position: relative">
    <el-table ref="subsidyApplication" :data="data" empty-text="暂无数据" :show-header="showHeader" :border="border"
      :stripe="strip" size="mini" :tooltip-effect="tooltipEffect" :span-method="objectSpanMethod"
      :row-class-name="tableRowClassName" :row-style="rowStyle" :cell-style="cellStyle"
      :header-cell-style="headerCellStyle" :header-row-style="headerRowStyle" v-loading="loading"
      element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" @select="handSelect"
      @select-all="handSelectAll" @sort-change="handleSortChange" @row-click="handleRow" @row-dblclick="handleRowDb"
      @cell-click="handleCell" @cell-dblclick="handleCellDb" @cell-mouse-enter="handleCellMouseEnter"
      @cell-mouse-leave="handleCellMouseLeave" @size-change="handleSizeChange" @current-change="handleCurrentChange">
      <el-table-column v-if="columnsType.type != ''" :type="columnsType.type" :label="columnsType.label"
        :width="columnsType.width" align="center"></el-table-column>


      <template v-for="(column, index) in columns">
        <!-- 没有子列的时候 -->
        <el-table-column v-if="!column.children" :key="index" :prop="column.prop" :label="column.label"
          :width="column.width" :min-width="column.minWidth" :align="column.align || 'left'"
          :sortable="column.sortable || false" :show-overflow-tooltip="column.tooltip == undefined || column.tooltip">

          <template slot-scope="scope">
            <span>
              <slot v-if="column.template" :name="column.prop" :row="scope.row" :index="scope.$index"></slot>
              <span v-else>{{ scope.row[column.prop]}}</span>
            </span>
          </template>
        </el-table-column>
        <!-- 如果有子列，渲染子列 -->
        <template v-else>
          <el-table-column :label="column.label">
            <template v-for="(child, childIndex) in column.children">
              <el-table-column :key="childIndex" :prop="child.prop" :label="child.label" :width="child.width"
                :min-width="child.minWidth" :align="child.align || 'left'" :sortable="child.sortable || false"
                :show-overflow-tooltip="child.tooltip == undefined || child.tooltip">
                <template slot-scope="scope">
                  <span>
                    <slot v-if="child.template" :name="child.prop" :row="scope.row" :index="scope.$index"></slot>
                    <span v-else>{{ scope.row[child.prop] }}</span>
                  </span>
                </template>
              </el-table-column>
            </template>
          </el-table-column>
        </template>

      </template>
    </el-table>
    <div class="table-bottom-selection">
      <slot name="selection"></slot>
    </div>
    <div v-if="isShow" class="pagenation">
      <el-pagination v-if="pagination" background @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="currentPage" :page-sizes="pageSizes" :page-size="pageSize" :layout="layout" :total="total">
      </el-pagination>
    </div>
    <div style="padding-top:20px;" v-else>
      已选条数{{checkedNum}}条，流转面积{{allNUm}}亩。
    </div>

  </div>
</template>

<script>
  export default {
    name: "TablePage",
    data() {
      return {
        checkAll: false,
        checkedNum: 0,//选择的条数
        allNUm: 0//流转的亩数
      };
    },
    computed: {
      pageValue: {
        //getter 方法
        get() {
          return this.pageSize;
        },
        set(newValue) {
          return newValue;
        },
      },
    },
    watch: {
      defaultSelectList: {
        handler(newList, oldList) {
          this.$nextTick(() => {
            console.log(this.defaultSelectList, '查看 this.defaultSelectList的数据是什么')

            // 获取 approvalNowYearsList 中所有 xmbh
            let defaultSelectXmbhList = this.defaultSelectList.map(item => item.xmbh);

            // 在 newList 中找到 xmbh 在 approvalXmbhList 中的项
            let selectedRows = this.data.filter(item => defaultSelectXmbhList.includes(item.xmbh));
            // console.log('查看数据selectedRows',selectedRows)
            // 使用 this.$refs.table.toggleRowSelection 来选择这些项
            let checkNum = 0
            let allNUm = 0
            if (selectedRows.length > 0) {
              // checkedNum++;
              selectedRows.forEach(item => {
                checkNum++;
                allNUm += item.flowAreaMu
                this.$refs.table.toggleRowSelection(item, true);
              });
            }
            this.checkedNum = checkNum
            this.allNUm = allNUm
          })
        },
        // immediate: true,
      },
    },
    props: {

      data: {
        //表格数据
        type: Array,
        default: function () {
          return [];
        },
      },
      defaultSelectList: {
        type: Array,
        default: function () {
          return [];
        },
      },
      isShow: {
        type: Boolean,
        default: function () {
          return true;
        },
      },
      columns: {
        //表格列
        type: Array,
        default: function () {
          return [];
        },
      },
      columnsType: {
        //对应列的类型。如果设置了 selection 则显示多选框；如果设置了 index 则显示该行的索引（从 1 开始计算）；如果设置了 expand 则显示为一个可展开的按钮
        type: Object,
        default: function () {
          return {
            type: "selection",
            label: "",
            width: "50",
          };
        },
      },

      layout: {
        type: String,
        default: "sizes, total, prev, pager, next",
      },
      pageSizes: {
        type: Array,
        default: function () {
          return [10, 20, 30, 40];
        },
      },
      total: {
        //总条数
        type: Number,
        default: 10,
      },
      currentPage: {
        //当前页
        type: Number,
        default: 1,
      },
      pageSize: {
        //一页显示多少条
        type: Number,
        default: 10,
      },
      pagination: {
        type: Boolean,
        default: true,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      border: {
        type: Boolean,
        default: false,
      },
      strip: {
        type: Boolean,
        default: false,
      },
      showHeader: {
        type: Boolean,
        default: true,
      },
      headerCellStyle: {
        type: Function,
        default: function () {
          return {};
        },
      },
      tooltipEffect: {
        type: String,
        default: "light",
      },
      rowStyle: {
        type: Function,
        default: function () {
          return {};
        },
      },
      // handleSelectable: {
      //   type: Function,
      //   default: function (row, index) {
      //     if (row.attr == 1) {
      //       return false;
      //     }
      //     return true;
      //   },
      // },

      headerRowStyle: {
        type: Function,
        default: function () {
          return {};
        },
      },
      cellStyle: {
        type: Function,
        default: function () {
          return "";
        },
      },
      pageBottomClass: {
        type: Boolean,
        default: true,
      },
    },
    methods: {
      // 带状态表格
      tableRowClassName({ row, rowIndex }) {

        if (!row.isHave) {
          return 'warning-row';
        }
        return '';
      },
      // 合并单元格
      objectSpanMethod({ row, column, rowIndex, columnIndex }) {
        if (columnIndex === 1) {
          let spanArr = this.getSpanArr(this.data, 'typeFarmerName');
          const _row = spanArr[rowIndex];
          const _col = _row > 0 ? 1 : 0;
          return {
            rowspan: _row,
            colspan: _col,

          };
        }

      },
      //处理合并的项
      getSpanArr(data, spanKey) {
        let that = this;
        let spanArr = [];
        let pos = '';
        for (let i = 0; i < data.length; i++) {
          if (i === 0) {
            spanArr.push(1);
            pos = 0;
          } else {
            // 判断当前元素与上一个元素是否相同
            if (data[i][spanKey] === data[i - 1][spanKey]) {
              spanArr[pos] += 1;
              spanArr.push(0);
            } else {
              spanArr.push(1);
              pos = i;
            }
          }
        }
        return spanArr;


      },
      handleCheckAllChange(val) {
        this.$refs["table"].toggleAllSelection(val);
      },

      handleSizeChange(val) {
        //每一页显示几条数据
        this.$emit("sizeChange", val);
      },
      handleCurrentChange(val) {
        // 当前第几页
        this.$emit("currentChange", val);
      },

      handSelect(selection, row) {
        // 当用户手动勾选数据行的 Checkbox 时触发的事件
        this.$emit("handSelect", selection);
        console.log(selection, '单选');

        // 使用 reduce 来计算总面积
        const totalMu = selection.reduce((sum, item) => {
          // const result = parseFloat(item.flowAreaMu.replace("亩", "")) || 0; // 转换为数字，并确保安全
          // console.log(result,'查看result')
          // return sum + result;
          const result = item.flowAreaMu
          return sum + result
        }, 0);

        this.allNUm = totalMu; // 注意变量名，这里应该是 totalMu 而不是 allNuM
        this.checkedNum = selection.length; // 选择的项数
      },
      handSelectAll(selection) {
        //用户进行全选或反选的时候数据的变化
        this.$emit("handSelectAll", selection);
        // 使用 reduce 来计算总面积
        const totalMu = selection.reduce((sum, item) => {
          // const result = parseFloat(item.flowAreaMu.replace("亩", "")) || 0; // 转换为数字，并确保安全
          // console.log(result,'查看result')
          // return sum + result;
          const result = item.flowAreaMu
          return sum + result
        }, 0);
        this.allNUm = totalMu; // 注意变量名，这里应该是 totalMu 而不是 allNuM

        this.checkedNum = selection.length
      },
      handleSortChange(column) {
        this.$emit("sortChange", column);
      },

      handleRow(row, column, event) {
        this.$emit("rowClick", {
          row,
          column,
          event,
        });
      },

      handleRowDb(row, column, event) {
        this.$emit("rowDbClick", {
          row,
          column,
          event,
        });
      },

      handleCell(row, column, cell, event) {
        this.$emit("cellClick", {
          row,
          column,
          cell,
          event,
        });
      },

      handleCellDb(row, column, cell, event) {
        this.$emit("cellDbClick", {
          row,
          column,
          cell,
          event,
        });
      },

      handleCellMouseLeave(row, column, cell, event) {
        this.$emit("cellMouseLeave", {
          row,
          column,
          cell,
          event,
        });
      },

      handleCellMouseEnter(row, column, cell, event) {
        this.$emit("cellMouseEnter", {
          row,
          column,
          cell,
          event,
        });
      },
    },
  };
</script>
<style lang="less" scoped>
  /deep/.el-table {
    thead {
      color: #333;
      background: #f3f3f3;
      font-weight: 500;

      tr {
        background: #f3f3f3;
      }

      th {
        padding: 12px 0;
        background: #f3f3f3;
        text-align: center;
      }
    }

    td {
      text-align: center;
    }
  }

  /deep/.el-table .warning-row {
    /* background: #EE9DB1 ; */
    color: #EE9DB1;
  }

  /deep/.el-table-column--selection {
    background: #fff
  }

  /deep/td:nth-child(2) {
    color: black; // 设置第二列单元格背景为白色
  }

  /*
*/
</style>
